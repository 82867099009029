import axios from 'axios';
import config from '../config/config';

const environment = "development";

export default function handleLogout() {
    localStorage.removeItem('gojimx_token');
    localStorage.removeItem('gojimx_phone');
    localStorage.removeItem('gojimx_username');
    localStorage.removeItem('gojimx_businessId');
    localStorage.removeItem('gojimx_business_name');
    localStorage.removeItem('gojimx_assistant_id');
    localStorage.removeItem('gojimx_baileys_status'); 
    localStorage.removeItem('gojimx_baileys_port'); 
    localStorage.removeItem('gojimx_needs_payment'); 
    localStorage.removeItem('gojimx_payment_type'); 

    window.location.reload();
};

export function decodeUnicode(str: string) {
    try {
        str = decodeURIComponent(str);
    } catch (e) {
        console.log('Error in URI decoding:', e);
    }

    str = str.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
        return String.fromCharCode(parseInt(grp, 16));
    });

    // Regular expression to keep standard ASCII characters and specific Spanish characters
    str = str.replace(/[^\x20-\x7EáéíóúÁÉÍÓÚñÑüÜ]/g, '');

    return str;
}

export function formatPhoneNumber(phoneString: string) {
    // Elimina la parte de @s.whatsapp.net
    const number = phoneString.split('@')[0];

    // Agrega el símbolo '+' al inicio
    const formattedNumber = '+' + number;

    // Divide el número en partes usando substrings
    const countryCode = formattedNumber.substring(0, 4);
    const areaCode = formattedNumber.substring(4, 6);
    const firstPart = formattedNumber.substring(6, 10);
    const secondPart = formattedNumber.substring(10);

    // Formatea el número
    return `${countryCode} ${areaCode} ${firstPart} ${secondPart}`;
  };

  export function getRawPhoneNumber(phoneString: string) {
    return '+' + phoneString.split('@')[0];
  };




export async function getWeekAppointments(selectedDate: string, businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');

        const response = await axios.get(`${config.backendURL}/week_appointments/${selectedDate}?business_id=${businessId}`, {
            headers: {
              Authorization: `Bearer ${gojimx_token}`
            }
          });
        console.log('[Client] Week appointments retrieved successfully.');
        return response.data;
    } catch (error) {
        console.error('[Client ERROR] Error getting week appointments:', error);
        throw error;
    }
}

export async function getAppointments(date: string, businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');

        const response = await axios.get(`${config.backendURL}/appointments?date=${date}&businessId=${businessId}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8', // Ensure correct charset
        'Authorization': `Bearer ${gojimx_token}`
      }
    });

        console.log('[Client] Appointments retrieved successfully.');
        return response.data;
    } catch (error) {
        console.error('[Client ERROR] Error getting appointments:', error);
        throw error;
    }
}

export async function getSkills(businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');
        console.log('Token from localStorage:', gojimx_token);
        const response = await axios.get(`${config.backendURL}/getSkills`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8', // Ensure correct charset
        'Authorization': `Bearer ${gojimx_token}`
      },
      params: {
        business_id: businessId
      }
    });

        console.log('[Client] Skills retrieved successfully.');
        return response.data.skills;
    } catch (error) {
        console.error('[Client ERROR] Error getting skills:', error);
        throw error;
    }
}

export async function validateToken() {
    const token = localStorage.getItem('gojimx_token');
    if (!token) {
        console.log('Token not found');
        handleLogout();
        return false; // Token no encontrado, retorna false
    }

    try {
        const response = await axios.get(`${config.backendURL}/validateToken`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Si la validación es exitosa, retorna true
        return true;
    } catch (error) {
        console.log('Token validation failed:', error);
        handleLogout();
        return false; // Error en la validación del token, retorna false
    }
}

export async function fetchBusinessStatus()  {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const business_id = localStorage.getItem('gojimx_businessId'); 
    if (!business_id || !gojimx_token) {
        console.error('Missing business_id or gojimx_token');
        return;
    }

    try {
        console.log(`Fetching business status from URL: ${config.backendURL}/get_business/${business_id}`);
        const response = await axios.get(`${config.backendURL}/get_business/${business_id}`, {
            headers: {
                Authorization: `Bearer ${gojimx_token}`
            }
        });
        const { renata_points } = response.data[0];
        if (Number(renata_points) <= 0 ) {
            localStorage.setItem('gojimx_needs_payment', 'true');
            window.location.replace('/payment'); 
        }
        const { baileys_status } = response.data[0];
        if (!baileys_status) {
            localStorage.setItem('gojimx_baileys_status', 'false');
            window.location.replace('/scanQR'); 
        }
        return response.data[0];
    } catch (error) {
        console.error('Error fetching business status:', error);
    }
};

export async function handleDeductionTransaction(tokens: number) {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const business_id = localStorage.getItem('gojimx_businessId');

    const currentDate = new Date();
    const type = "subtract";
    let msgResp = "Error";

    const usageData = {
        usage: tokens,
        transaction_type: type
    }
    const transactionData = {
        business_id: business_id,
        transaction_date: currentDate,
        transaction_type: type,
        transaction_ammount: tokens,
        transaction_source: "knowledgeBase"
    }
    const configuration = {
        headers: {
            'Authorization': `Bearer ${gojimx_token}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.patch(`${config.backendURL}/modifyPoints/${business_id}`, usageData, configuration);
        
        if(response.status === 200){
            try {
                const response = await axios.post(`${config.backendURL}/addTransaction`, transactionData, configuration);
                if(response.status === 201){
                    console.log('[Renata-Assistant] New transaction saved successfully');
                    msgResp = 'transaction saved successfully';
                }
            } catch (error) {
                console.error('[Renata-Assistant] Could not save transaction', error);
                msgResp = 'Could not save transaction';
            }
        }
    } catch (error) {
        console.error('[Renata-Assistant] Could not save renata-points modification', error);
        msgResp = "Could not save renata-points modification";
    }
    return { message: msgResp};
}